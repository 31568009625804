import React, { useEffect, useState } from "react";
import { List, Map } from "immutable";
import { compose, getContext } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles, Paper } from "@material-ui/core";
import { Collapse } from "react-collapse";
import OrderDetailsDialogHeader from "./OrderDetailsDialogHeader";
import OrderDetailsDialogTabs, {
  BRIEF_TAB,
  COD_HISTORY_TAB,
  COURIER_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  MAP_TAB,
  PROOF_OF_PAYMENT,
  SIGNATURE_TAB,
} from "../OrderDetailsDialogTabs";
import ModalPaper from "../../ui-core/ModalPaper";
import { renderIf } from "../../../helpers/HOCUtils";
import FlexBox, { JUSTIFY_CENTER } from "../../ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../../ui-core/CustomButton";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import fp from "lodash/fp";
import {
  batchAsyncUpdateOrder,
  updateOrderIssuance,
  updateRecipientPostcode,
  updateRTOInternational,
  uploadOrderSignature,
} from "../../../api/admin/AdminOrderApi";
import ResponseError from "../../../helpers/ResponseError";
import OrderSortingRedirectOrderDialog from "../../order-outbound-sorting/OrderSortingRedirectOrderDialog";
import {
  getAVVALUserId,
  getUserId,
  getUserJurisdiction,
  getUserWarehouse,
  getUserWarehouseId,
  hasUserPermission,
} from "../../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { OrderSortingDB } from "../../../firebase/OrderSortingDB";
import BatchRTOOrdersFormDialogWrapper from "../../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import OrderDetailsDialogIssuance2 from "../OrderDetailsDialogIssuance2";
import {
  CANCELLED,
  isNotFinalStatus,
  ISSUED_TO_RECIPIENT,
  RETURNED_TO_ORIGIN,
  showedOrderButton,
} from "../../../constants/OrderStatusCodes";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import { getValue, isEmpty, toJS } from "../../../helpers/DataUtils";
import _ from "lodash";
import { Check, CheckCircle, DeleteForever, Warning } from "@material-ui/icons";
import {
  avvalServiceTypes,
  COD_RECEIPT_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RefundInfo,
  RTO_RECEIPT_URL,
  serviceId,
  urlAVVAL,
} from "../../orders-core/AdminPostForm";
import { postBatchChargeItems } from "../../../api/admin/AdminBatchApi";
import {
  CARD,
  CASH,
  CREDIT_BALANCE,
  freePayTypes,
} from "../../../constants/OrderPaymentTypes";
import {
  formatDateTimeToUrlWithSeconds,
  formatErrorMessage,
} from "../../../helpers/FormatUtils";
import {
  COD,
  CUSTOMS_FEE,
  FORWARDING_FEE,
  INSURANCE_FEE,
  NOTIFICATION_FEE,
  RTO_INTERNATI0NAL,
  SERVICE,
  SERVICE_ADDED,
  SERVICE_MAIN,
} from "../../../constants/OrderChargeItemTypes";
import { green, orange, red } from "@material-ui/core/colors";
import { getCheckId, setReceipt } from "../../../api/v2/admin/AdminOrderApi";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import { DEFAULT_SUPPLIER } from "../../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";
import {
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../../reducers/MarketplaceReducer";
import { avvalSearchUrl } from "../../orders-core/CommissionTINDialog";
import { getPDF } from "../../../helpers/FormUtils";
import { SIMPLE } from "../../../constants/TransportationType";
import BatchRTOPPriceOrdersDialog from "../../orders-core/BatchRTOPPriceOrdersDialog";
import { serviceTypes } from "./OrderDetailsDialogForOrderList";

export const calculateVAT = x => _.round(_.divide(_.multiply(x, 12), 1.12));
export const RECEIPT_RETURN_URL = `${API_V2_ROOT_URL}/receipt_return`;
const useStyles = makeStyles(theme => ({
  paper: { fontFamily: "Blogger Sans" },
  background: {
    zIndex: -10,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    backgroundColor: "red",
  },

  topBackground: {
    zIndex: 1,
    flex: "1 1 0%",
    borderRadius: 0,
    transition: "background 0.5s ease",
    backgroundColor: theme.palette.primary.main,
  },

  bottomBackground: { backgroundColor: "#ebebeb", flex: "1 1 0%" },

  loading: {
    top: 0,
    width: "100%",
    height: "300px",
    textAlign: "center",
    lineHeight: "300px",
    position: "absolute",
    transition: "opacity 1s ease",
  },
  visibleLoader: { opacity: 1 },
  invisibleLoader: { opacity: 0, top: "2000px" },

  collapse: {
    overflow: "hidden",
    marginLeft: "-3px",
    paddingLeft: "3px",
    marginBottom: "-3px",
    paddingBottom: "3px",
    marginRight: "-3px",
    paddingRight: "3px",
  },

  container: { transition: "opacity 1s ease" },
  visibleContainer: { opacity: 1 },
  invisibleContainer: { opacity: 0 },
}));
const enhancer = compose(
  connect(
    state => ({
      hasAccessToOrderIssuance: hasUserPermission(
        state,
        "HAS_ACCESS_TO_ORDER_ISSUANCE",
      ),
      hasAccessToOrderRTOPayment: hasUserPermission(
        state,
        "RTO_INTERNATIONAL_WITH_PAYMENT",
      ),
      userIdAVVAL: getAVVALUserId(state),
      userId: getUserId(state),
      userWarehouse: toJS(getUserWarehouse(state)),
      userWarehouseId: getUserWarehouseId(state),
      userJurisdictionId: getValue(getUserJurisdiction(state), "id"),
      isOtpSettingsServiceType: isOtpSettingsServiceType(state),
      isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  renderIf("open"),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

const options = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

function OrderDetailsDialog(props) {
  const classes = useStyles();
  const {
    getLocalisationMessage,
    userIdAVVAL,
    order,
    showErrorMessage: showErrorMessage1,
    // eslint-disable-next-line no-shadow
    isOtpSettingsServiceType,
    // eslint-disable-next-line no-shadow
    isOtpSettingsTransportationType,
  } = props;
  const [checkID, setCheckID] = useState(0);
  const [checkCODID, setCheckCODID] = useState(0);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingAVVAL, setIsLoadingAVVAL] = useState(false);
  const [isLoadingConnectingAVVAL, setIsLoadingConnectingAVVAL] = useState(
    false,
  );
  const [isConnectedAVVAL, setIsConnectedAVVAL] = useState(false);
  const [isOpenRedirect, setIsOpenRedirect] = useState(false);
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [isOpenIssuance, setIsOpenIssuance] = useState(false);
  const [isAssignDriver, setIsAssignDriver] = useState(false);
  const [errorOtpData, setErrorOtpError] = useState("");

  const [isOpenRTOPrice, setIsOpenRTOPrice] = useState(false);
  const [isLoadingRTOPrice, setIsLoadingRTOPrice] = useState(false);

  const db = new OrderSortingDB(props.userWarehouseId);
  const orderId = order.get("id");
  const barcode = order.get("barcode");
  const isPaid = order.get("paid");
  const chargeItems = toJS(order.get("charge_items")) || [];
  const oldChargeItems = toJS(order.get("avval_charge_items")) || [];
  const avvalReceipt = toJS(order.get("avval_receipt") || RefundInfo);
  const createdByUserId = order.getIn(["created_by_user", "id"]);
  const paymentType = order.get("payment_type");
  const orderStatus = order.get("status");
  const isThisUserCreatedOrder = props.userId === createdByUserId;
  const total = _.get(
    chargeItems.find(v => v.charge_type === "total"),
    "charge",
    0,
  );
  const orderWarehouseId = order.getIn(["warehouse", "id"]);
  const isOrderLocatedHere = props.userWarehouseId === orderWarehouseId;
  const isCanShowCardTypeError =
    order.get("payment_type") === CARD &&
    !order.get("card_type") &&
    !order.get("ppt_id");
  const isFreePayTypeOrTotalZero =
    freePayTypes.find(v => v === paymentType) || total === 0;
  const charges = chargeItems
    .filter(
      x =>
        x.charge_type === INSURANCE_FEE ||
        x.charge_type === CUSTOMS_FEE ||
        x.charge_type === FORWARDING_FEE ||
        x.charge_type === SERVICE ||
        x.charge_type === SERVICE_ADDED ||
        x.charge_type === NOTIFICATION_FEE,
    )
    .filter(v => v.charge);
  const oldCharges = oldChargeItems
    .filter(
      x =>
        x.charge_type === INSURANCE_FEE ||
        x.charge_type === CUSTOMS_FEE ||
        x.charge_type === FORWARDING_FEE ||
        x.charge_type === SERVICE ||
        x.charge_type === SERVICE_ADDED ||
        x.charge_type === NOTIFICATION_FEE,
    )
    .filter(v => v.charge);

  const [stateReceiptRTO, setStateReceiptRTO] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RTO_RECEIPT_URL,
  });

  const [refreshChekId, setRefreshChekId] = useState(false);

  useEffect(() => {
    if (!isEmpty(order)) {
      if (
        !freePayTypes.includes(paymentType) &&
        order.get("status") !== CANCELLED
      ) {
        getCheckId().then(res => setCheckID(_.get(res, "data")));
      }
    }
  }, [order, refreshChekId]);

  useEffect(() => {
    if (isOpenRTOPrice) {
      getCheckId().then(res => setCheckID(_.get(res, "data")));
    }
  }, [isOpenRTOPrice, refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (checkID === 0) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [order, checkID]);

  useEffect(() => {
    if (userIdAVVAL) {
      setIsLoadingConnectingAVVAL(true);
      fetch(urlAVVAL, {
        method: "POST",
        body: JSON.stringify({
          method: "get-info",
          userId: userIdAVVAL,
          serviceId,
          request: {
            login: "123",
          },
        }),
      })
        .then(() => {
          setIsConnectedAVVAL(true);
        })
        .catch(() => setIsConnectedAVVAL(false))
        .finally(() => setIsLoadingConnectingAVVAL(false));
    }
  }, [userIdAVVAL]);

  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const chargeItemRTO =
    _.find(chargeItems, { charge_type: RTO_INTERNATI0NAL }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const transportationType = order.get("transportation_type");
  const serviceType = order.getIn(["service_type", "code"]);
  const orderJS = toJS(order);
  const contentItems = _.get(orderJS, "content_items", []);

  const isPaidRTO = chargeItemRTO.paid;

  const [refreshCheckCODId, setRefreshCheckCODId] = useState(false);

  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkCODID > 0)) {
      getCheckId().then(res => setCheckCODID(_.get(res, "data")));
    }
  }, [refreshCheckCODId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkCODID > 0)) {
      const refresh = setInterval(
        () => setRefreshCheckCODId(prev => !prev),
        300,
      );
      return () => clearInterval(refresh);
    }
  }, [checkCODID, isOpenIssuance, isHaveCOD]);

  const [packageCode, setPackageCode] = useState("");

  useEffect(() => {
    if (order) {
      const optionsMixkCode = {
        method: "POST",
        headers: {
          Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_inn_spic: {
            spic: "10105001001000000",
          },
        }),
      };

      fetch(avvalSearchUrl, optionsMixkCode)
        .then(response => response.json())
        .then(res => {
          if (res && res.mess === "success") {
            setPackageCode(getValue(res, "data.packageNames[0].code"));
          }
        });
    }
  }, [order, isOpenRTOPrice]);

  const createCODAVVALData = (codPaymentType, cardType, pptId, QQS) => {
    const customerName = _.get(orderJS, "customer.name", "");
    const servicesCod = [];
    const commissionINNItems = [];

    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);
      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: _.get(item, "service_id", ""),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: 0,
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      codPaymentType === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      codPaymentType === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(orderJS, "barcode", ""),
      checkID: checkCODID,
      chNumber: _.get(orderJS, "chNumber", orderId),
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };
  const createAVVALData = isCancel => {
    // const mergeDuplicates =
    //   (field, uniqueField) =>
    //   (source = [], value) => {
    //     const target = source.find(
    //       (item) => item[uniqueField] === value[uniqueField],
    //     );
    //     if (target) target[field].push(value[field]);
    //     else source.push({ ...value, [field]: [value[field]] });
    //     return source;
    //   };
    const servicesAvval = [];
    const Items = [];
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          _.get(v, "name", "") && _.get(v, "name", "").length
            ? _.get(v, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: packageCode,
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });
    const tempCharges = isCancel ? oldCharges : charges;
    tempCharges
      .filter(x => x.payment_type === CASH || x.payment_type === CARD)
      // .reduce(mergeDuplicates("charge", "id"), [])
      .forEach(v => {
        const found = avvalServiceTypes.find(
          j => getValue(v, "charge_type") === j.type,
        );
        if (getValue(v, "charge_type") === SERVICE) {
          const temp = {
            ...v,
            charge_type: SERVICE_MAIN,
            id: order.getIn(["package_type", "id"]),
          };
          createService(temp);
          createRequest(temp, temp.charge);
        } else if (found) {
          const temp = { ...v, id: found.id, name: found.name };
          createService(temp);
          createRequest(temp, temp.charge);
        } else {
          createService(v);
          createRequest(v, v.charge);
        }
      });
    const getTotalPrice = (items, orderPaymentType) => {
      const reducedSum = items
        .filter(v => v.payment_type === orderPaymentType)
        .reduce(
          (totalReducer, item) => _.get(item, "charge") + totalReducer,
          0,
        );
      return _.multiply(reducedSum, 100);
    };
    const ReceivedCash = getTotalPrice(tempCharges, CASH);
    const ReceivedCard = getTotalPrice(tempCharges, CARD);

    const additionalService = chargeItems.filter(
      chargeItem => chargeItem.charge_type === SERVICE_ADDED,
    );
    const additionalPptId =
      additionalService && additionalService.length > 0
        ? additionalService[0].ppt_id
        : null;
    const additionalCardType =
      additionalService && additionalService.length > 0
        ? additionalService[0].card_type
        : null;

    return {
      method: isCancel ? "cancel-services" : "sale-services",
      userId: userIdAVVAL,
      serviceId,
      checkID,
      chNumber: order.get("chNumber", orderId),
      barcode: order.get("barcode"),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo: isCancel ? avvalReceipt : RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            order.get("card_type") === "CORPORATIVE"
              ? 1
              : order.get("card_type") === "PRIVATE"
              ? 2
              : additionalCardType === "CORPORATIVE"
              ? 1
              : additionalCardType === "PRIVATE"
              ? 2
              : "",
          PPTID: order.get("ppt_id") || additionalPptId,
        },
      },
    };
  };
  const optionsPayment = (isCancel, tosender) => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        barcode,
        tosender,
        method: isCancel ? "cancel-services" : "sale-services",
      },
    }),
  });

  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        getLocalisationMessage(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(
        getLocalisationMessage("the_payment_was_not_successful"),
      );
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${getLocalisationMessage(
        "error_msg_from_avval",
      )} : ${_.get(response, "resInfo.message")}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };

  const createAVVALDataRTO = (rtoPaymentType, orderDto) => {
    const servicesAvval = [];
    const customerName = _.get(orderDto, "customer.name", "");
    const Items = [];
    const chargesItems = _.get(orderDto, "charge_items", []);
    const chargeItemRTOInternational =
      _.find(chargesItems, { charge_type: RTO_INTERNATI0NAL }) || {};

    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: packageCode,
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });

    chargesItems
      .filter(x => x.charge_type === RTO_INTERNATI0NAL)
      .forEach(v => {
        createService(v);
        createRequest(v, v.charge);
      });

    const ReceivedCash =
      rtoPaymentType === CASH
        ? _.get(chargeItemRTOInternational, "charge") * 100
        : 0;
    const ReceivedCard =
      rtoPaymentType === CARD
        ? _.get(chargeItemRTOInternational, "charge") * 100
        : 0;
    return {
      method: "sale-services",
      userId: userIdAVVAL,
      tosender: 1,
      serviceId,
      barcode: _.get(orderDto, "barcode", ""),
      checkID,
      chNumber: _.get(orderDto, "chNumber", orderId),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            _.get(chargeItemRTOInternational, "card_type") === "CORPORATIVE"
              ? 1
              : _.get(chargeItemRTOInternational, "card_type") === "PRIVATE"
              ? 2
              : "",
          PPTID: _.get(chargeItemRTOInternational, "ppt_id"),
        },
      },
    };
  };
  const otpSettingsServiceTypes = isOtpSettingsServiceType
    ? isOtpSettingsServiceType.toJS()
    : [];
  const otpSettingsTransportationTypes = isOtpSettingsTransportationType
    ? isOtpSettingsTransportationType.toJS()
    : [];

  const [isLoadingCOD, setIsLoadingCOD] = useState(false);
  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });

  const showOtpSettings =
    otpSettingsServiceTypes.includes(order.getIn(["service_type", "code"])) &&
    otpSettingsTransportationTypes.includes(order.get("transportation_type"));
  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <div
        className={cx(classes.loading, {
          [classes.invisibleLoader]: !props.isLoading,
          [classes.visibleLoader]: props.isLoading,
        })}
      >
        Loading...
      </div>

      <div
        className={cx(classes.container, {
          [classes.visibleContainer]: !props.isLoading,
          [classes.invisibleContainer]: props.isLoading,
        })}
      >
        <div className={classes.background}>
          <Paper
            className={classes.topBackground}
            // style={{
            //   backgroundColor: darken(props.theme.palette.primary.main, 0.2),
            // }}
          />
          <div className={classes.bottomBackground} />
        </div>

        <OrderDetailsDialogHeader
          order={order}
          getDriverUrl={props.getDriverUrl}
          getSupplierUrl={props.getSupplierUrl}
          getCustomerUrl={props.getCustomerUrl}
          lastSyncDate={props.lastSyncDate}
          getReverseUrl={id => props.getReverseUrl(id)}
        />

        <OrderDetailsDialogTabs
          tab={props.tab}
          withoutCourierTab={props.withoutCourierTab}
          order={order}
          onTabChange={props.onTabChange}
          onEditClick={props.onEditClick}
          onEditCostsClick={props.onEditCostsClick}
          openRTODialog={props.openRTODialog}
          createDriverSuggestHref={props.createDriverSuggestHref}
          onShowSuggestedDriverLink={props.onShowSuggestedDriverLink}
          withoutSignaturesTab={props.withoutSignaturesTab}
          onCancelOrderClick={props.onCancelOrderClick}
          isOrderClosed={props.isOrderClosed}
          withoutCustomerTab={props.withoutCustomerTab}
          withoutMap={props.withoutMap}
          withCODHistory={props.withCODHistory}
          withTaxInvoice={props.withTaxInvoice}
        />

        {orderId > 0 && (
          <Collapse isOpened={!props.isLoading}>{props.children}</Collapse>
        )}
        <OrderSortingRedirectOrderDialog
          open={isOpenRedirect}
          order={order}
          onSubmit={({ index }) =>
            updateRecipientPostcode({
              index: fp.get("name", index),
              barcodes: [barcode],
              is_redirected: true,
            }).catch(ResponseError.throw)
          }
          onSubmitFail={showErrorMessage1}
          onSubmitSuccess={() => {
            setIsOpenRedirect(false);
            db.batchReloadOrders([barcode])
              .toPromise()
              .then(() => {
                db.batchUpdateOrderBins([barcode], null).toPromise();
                setIsOpenRedirect(false);
              })
              .finally(() => props.onRequestRefresh());
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_updated",
                "Successfully Updated",
              ),
            );
          }}
          onRequestClose={() => setIsOpenRedirect(false)}
        />
        <BatchRTOOrdersFormDialogWrapper
          open={isOpenRTO}
          initialValues={{
            orderBarcodes: [barcode],
            warehouse: { id: props.userWarehouseId },
          }}
          onRequestClose={() => setIsOpenRTO(false)}
          onSubmitSuccess={response => {
            props.onRequestRefresh();
            setIsOpenRTO(false);
            const batchId = fp.get("data.id", response);
            if (batchId) {
              props.setLocationQuery(fp.set("batch_id", batchId));
              // props.setState(fp.set("doNotRemoveOrders", true));
            }
          }}
        />

        <BatchAssignOrdersToDriverFormDialogWrapper
          open={isAssignDriver}
          disableBarcodes={true}
          initialValues={{
            orderBarcodes: [barcode],
            warehouse: { id: props.userWarehouseId },
          }}
          onRequestClose={() => {
            setIsAssignDriver(false);
          }}
          onSubmitSuccess={res => {
            props.onRequestRefresh();
            setIsAssignDriver(false);
            props.setLocationQuery(fp.set("batch_id", res.data.id));
          }}
        />

        <BatchRTOPPriceOrdersDialog
          open={isOpenRTOPrice}
          isLoadingRTOPrice={isLoadingRTOPrice}
          close={() => {
            setIsOpenRTOPrice(false);
          }}
          initialValues={{
            barcode,
            status: RETURNED_TO_ORIGIN,
            paymentType: getValue(chargeItemRTO, "payment_type")
              ? getValue(chargeItemRTO, "payment_type")
              : CASH,
            price: getValue(chargeItemRTO, "charge")
              ? getValue(chargeItemRTO, "charge")
              : order.getIn(["package_type", "price", "total"]),
            cardType: getValue(chargeItemRTO, "card_type"),
            pptId: getValue(chargeItemRTO, "ppt_id"),
          }}
          onSubmit={values => {
            setIsLoadingRTOPrice(true);
            updateRTOInternational(toSnakeCase(values))
              .then(r => {
                if (r && r.status === "success") {
                  setTimeout(() => {
                    fetch(avvalSearchUrl, optionsPayment(false, 1))
                      .then(response => response.json())
                      .then(rescheck => {
                        if (rescheck && rescheck.res_id === 103) {
                          setReceipt(orderId, {
                            check_number: orderId,
                            receipt: {
                              TerminalID: getValue(
                                rescheck,
                                "data.terminal_id",
                              ),
                              ReceiptSeq: getValue(
                                rescheck,
                                "data.tartib_raqami",
                              ),
                              DateTime: getValue(rescheck, "data.date_time"),
                              FiscalSign: getValue(
                                rescheck,
                                "data.fiskal_belgi",
                              ),
                              AppletVersion: getValue(rescheck, "data.versiya"),
                              QRCodeURL: getValue(rescheck, "data.link"),
                              checkID,
                            },
                            type: "RTO",
                            rto_payment_type: _.get(
                              values,
                              "paymentType",
                              CASH,
                            ),
                          })
                            .then(resAvval => {
                              if (resAvval) {
                                setTimeout(
                                  () => setIsLoadingRTOPrice(false),
                                  1500,
                                );
                                props.onRequestRefresh();

                                if (props.refreshOrderList) {
                                  props.refreshOrderList();
                                }
                                props.showSuccessMessage(
                                  getLocalisationMessage(
                                    "successfully_created",
                                    "Successfully Created",
                                  ),
                                );

                                setCheckID(0);
                              }
                            })
                            .catch(err => {
                              setIsLoadingRTOPrice(false);
                              showErrorMessage1(err);
                            })
                            .finally(() => {
                              setIsLoadingRTOPrice(false);
                              getPDF(
                                stateReceiptRTO,
                                setStateReceiptRTO,
                                IS_PRINTING,
                                orderId,
                                showErrorMessage1,
                              );
                              setIsOpenRTOPrice(false);
                            });
                        } else {
                          fetch(urlAVVAL, {
                            method: "POST",
                            body: JSON.stringify(
                              createAVVALDataRTO(
                                _.get(values, "paymentType", CASH),
                                _.get(r, "data", {}),
                              ),
                            ),
                          })
                            .then(response => response.json())
                            .then(res => {
                              if (res && getValue(res, "code") === "1") {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo.code")),
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingRTOPrice(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                getValue(res, "code") === "-1"
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo")),
                                    getValue(res, "resInfo"),
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingRTOPrice(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                (getValue(res, "resInfo.result.QRCodeURL") ===
                                  null ||
                                  !getValue(res, "resInfo.result.QRCodeURL"))
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    "the_payment_was_not_successful",
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingRTOPrice(false),
                                  1500,
                                );
                              } else if (res && res.msg === "succes") {
                                if (checkAVVALres(res)) {
                                  setReceipt(orderId, {
                                    check_number: orderId,
                                    receipt: {
                                      ..._.get(res, "resInfo.result"),
                                      checkID,
                                    },
                                    type: "RTO",
                                    rto_payment_type: _.get(
                                      values,
                                      "paymentType",
                                      CASH,
                                    ),
                                  })
                                    .then(resAvval => {
                                      if (resAvval) {
                                        setTimeout(
                                          () => setIsLoadingRTOPrice(false),
                                          1500,
                                        );
                                        props.onRequestRefresh();

                                        if (props.refreshOrderList) {
                                          props.refreshOrderList();
                                        }
                                        props.showSuccessMessage(
                                          getLocalisationMessage(
                                            "successfully_created",
                                            "Successfully Created",
                                          ),
                                        );
                                      }

                                      setCheckID(0);
                                    })
                                    .catch(e => {
                                      setIsLoadingRTOPrice(false);
                                      showErrorMessage1(e);
                                    })
                                    .finally(() => {
                                      setIsOpenRTOPrice(false);
                                      getPDF(
                                        stateReceiptRTO,
                                        setStateReceiptRTO,
                                        IS_PRINTING,
                                        orderId,
                                        showErrorMessage1,
                                      );
                                      setIsLoadingRTOPrice(false);
                                    });
                                }
                              } else {
                                setTimeout(
                                  () => setIsLoadingRTOPrice(false),
                                  1500,
                                );
                              }
                            })
                            .catch(() => {
                              showErrorMessage1(
                                getLocalisationMessage(
                                  "user_is_not_connected_to_avval",
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            });
                        }
                      })
                      .catch(() => {
                        setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                      }, 300);
                  }, 1000);
                }
              })
              .catch(err => {
                showErrorMessage1(err);
                setTimeout(() => setIsLoadingRTOPrice(false), 1500);
              });
          }}
        />

        {isOpenIssuance && (
          <OrderDetailsDialogIssuance2
            errorOtpData={errorOtpData}
            setErrorOtpError={setErrorOtpError}
            showOtpSettings={showOtpSettings}
            open={isOpenIssuance}
            isHaveCOD={isHaveCOD}
            isLoadingCOD={isLoadingCOD}
            chargeItemCOD={_.get(chargeItemCOD, "charge")}
            close={() => {
              setIsOpenIssuance(false);
              setIsLoadingCOD(false);
            }}
            initialValues={{
              name: order.getIn(["recipient_data", "name"]),
              without_otp: !showOtpSettings,
            }}
            header={getLocalisationMessage("recipient", "Recipient")}
            orderId={order.get("id")}
            onSubmit={values => {
              setIsLoadingCOD(true);
              if (isHaveCOD) {
                fetch(avvalSearchUrl, optionsPayment())
                  .then(response => response.json())
                  .then(rescheck => {
                    if (rescheck && rescheck.res_id === 103) {
                      setReceipt(orderId, {
                        check_number: orderId,
                        cod_payment_type: _.get(values, "codPaymentType", CASH),
                        card_type: getValue(values, "cardType"),
                        ppt_id: getValue(values, "pptId"),
                        type: "COD",
                        receipt: {
                          TerminalID: getValue(rescheck, "data.terminal_id"),
                          ReceiptSeq: getValue(rescheck, "data.tartib_raqami"),
                          DateTime: getValue(rescheck, "data.date_time"),
                          FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                          AppletVersion: getValue(rescheck, "data.versiya"),
                          QRCodeURL: getValue(rescheck, "data.link"),
                          checkCODID,
                        },
                      })
                        .then(() => {
                          updateOrderIssuance(orderId, toSnakeCase(values))
                            .then(() => {
                              uploadOrderSignature(orderId, {
                                name: values.name,
                                order_id: orderId,
                                phone: "",
                                photo_id: values.photoId,
                                signature_id: values.signatureId,
                                type: "delivery_signature",
                              })
                                .then(res2 => {
                                  props.showSuccessMessage(res2.status);
                                  setIsOpenIssuance(false);
                                  setRefreshChekId(prev => !prev);
                                })
                                .catch(err => {
                                  setIsLoadingCOD(false);
                                  showErrorMessage1(err);
                                })
                                .finally(() => {
                                  setIsLoadingCOD(false);
                                  getPDF(
                                    stateReceiptCOD,
                                    setStateReceiptCOD,
                                    IS_PRINTING,
                                    orderId,
                                    showErrorMessage1,
                                  );
                                });

                              setRefreshChekId(prev => !prev);
                            })
                            .catch(err => {
                              setErrorOtpError(formatErrorMessage(err));

                              setIsLoadingCOD(false);
                              showErrorMessage1(err);
                            });
                        })
                        .catch(err => {
                          setIsLoadingCOD(false);
                          showErrorMessage1(err);
                        });
                    } else {
                      fetch(
                        avvalSearchUrl,
                        options(contentItems[0].commission_tin),
                      )
                        .then(response => response.json())
                        .then(resAvval => {
                          if (resAvval && resAvval.mess === "success") {
                            const QQS = resAvval.data.nds_active ? 12 : 0;
                            fetch(urlAVVAL, {
                              method: "POST",
                              body: JSON.stringify(
                                createCODAVVALData(
                                  _.get(values, "codPaymentType", CASH),
                                  getValue(values, "cardType"),
                                  getValue(values, "pptId"),
                                  QQS,
                                ),
                              ),
                            })
                              .then(response => response.json())
                              .then(res => {
                                if (res && getValue(res, "code") === "1") {
                                  showErrorMessage1(
                                    getLocalisationMessage(
                                      fp.toString(
                                        getValue(res, "resInfo.code"),
                                      ),
                                      getValue(res, "resInfo.code"),
                                    ),
                                  );
                                  setIsLoadingCOD(false);
                                } else if (
                                  res &&
                                  getValue(res, "code") === "-1"
                                ) {
                                  showErrorMessage1(
                                    getLocalisationMessage(
                                      fp.toString(getValue(res, "resInfo")),
                                      getValue(res, "resInfo"),
                                    ),
                                  );
                                  setIsLoadingCOD(false);
                                } else if (
                                  res &&
                                  (getValue(res, "resInfo.result.QRCodeURL") ===
                                    null ||
                                    !getValue(res, "resInfo.result.QRCodeURL"))
                                ) {
                                  showErrorMessage1(
                                    getLocalisationMessage(
                                      "the_payment_was_not_successful",
                                    ),
                                  );
                                  setIsLoadingCOD(false);
                                } else if (res && res.msg === "succes") {
                                  if (checkAVVALres(res)) {
                                    setReceipt(orderId, {
                                      check_number: orderId,
                                      vat_active: resAvval.data.nds_active,
                                      cod_payment_type: _.get(
                                        values,
                                        "codPaymentType",
                                        CASH,
                                      ),
                                      card_type: getValue(values, "cardType"),
                                      ppt_id: getValue(values, "pptId"),
                                      type: "COD",
                                      receipt: {
                                        ..._.get(res, "resInfo.result"),
                                        checkCODID,
                                      },
                                    })
                                      .then(() => {
                                        updateOrderIssuance(
                                          orderId,
                                          toSnakeCase(values),
                                        )
                                          .then(() => {
                                            uploadOrderSignature(orderId, {
                                              name: values.name,
                                              order_id: orderId,
                                              phone: "",
                                              photo_id: values.photoId,
                                              signature_id: values.signatureId,
                                              type: "delivery_signature",
                                            })
                                              .then(res2 => {
                                                props.showSuccessMessage(
                                                  res2.status,
                                                );
                                                setIsOpenIssuance(false);
                                              })
                                              .catch(err => {
                                                setIsLoadingCOD(false);
                                                showErrorMessage1(err);
                                              })
                                              .finally(() => {
                                                setIsLoadingCOD(false);
                                                getPDF(
                                                  stateReceiptCOD,
                                                  setStateReceiptCOD,
                                                  IS_PRINTING,
                                                  orderId,
                                                  showErrorMessage1,
                                                );
                                              });
                                            setRefreshChekId(prev => !prev);
                                          })
                                          .catch(err => {
                                            setErrorOtpError(
                                              formatErrorMessage(err),
                                            );

                                            setIsLoadingCOD(false);
                                            showErrorMessage1(err);
                                          });
                                      })
                                      .catch(err => {
                                        setIsLoadingCOD(false);
                                        showErrorMessage1(err);
                                      });
                                  }
                                } else {
                                  setIsLoadingCOD(false);
                                }
                              })
                              .catch(() => {
                                setIsLoadingCOD(false);
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    "user_is_not_connected_to_avval",
                                  ),
                                );
                              });
                          }
                        });
                    }
                  });
              } else {
                updateOrderIssuance(orderId, toSnakeCase(values))
                  .then(() => {
                    uploadOrderSignature(orderId, {
                      name: values.name,
                      order_id: orderId,
                      phone: "",
                      photo_id: values.photoId,
                      signature_id: values.signatureId,
                      type: "delivery_signature",
                    })
                      .then(res => {
                        props.showSuccessMessage(res.status);
                        setIsOpenIssuance(false);
                      })
                      .catch(err => showErrorMessage1(err));
                  })
                  .catch(err => {
                    setErrorOtpError(formatErrorMessage(err));
                    showErrorMessage1(err);
                  })
                  .finally(() => setIsLoadingCOD(false));
              }
            }}
          />
        )}
        <FlexBox
          direction="column"
          flex={true}
          style={{ marginBottom: "1rem" }}
        >
          {!isFreePayTypeOrTotalZero && !isPaid && (
            <FlexBox
              style={{ marginBottom: "0.5rem" }}
              justify={JUSTIFY_CENTER}
              flex={true}
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                    color: isLoadingConnectingAVVAL
                      ? orange[500]
                      : isConnectedAVVAL
                      ? green[500]
                      : red[500],
                  }}
                >
                  {getLocalisationMessage(
                    !userIdAVVAL
                      ? "user_profile_is_not_connected_with_avval_profile"
                      : isLoadingConnectingAVVAL
                      ? "connecting_to_avval"
                      : isConnectedAVVAL
                      ? "user_is_connected_to_avval"
                      : "user_is_not_connected_to_avval",
                  )}
                </span>
                {isLoadingConnectingAVVAL ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : isConnectedAVVAL ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Warning style={{ color: red[500] }} />
                )}
              </h5>
            </FlexBox>
          )}
          {!isFreePayTypeOrTotalZero && !isPaid && isCanShowCardTypeError && (
            <FlexBox
              style={{ marginBottom: "0.5rem" }}
              justify={JUSTIFY_CENTER}
              flex={true}
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                    color: isLoadingConnectingAVVAL
                      ? orange[500]
                      : isConnectedAVVAL
                      ? green[500]
                      : red[500],
                  }}
                >
                  {getLocalisationMessage("the_accept_the_payment_first_edit")}
                </span>
                {isLoadingConnectingAVVAL ? (
                  <CircularProgress size={20} style={{ color: orange[500] }} />
                ) : isConnectedAVVAL ? (
                  <CheckCircle style={{ color: green[500] }} />
                ) : (
                  <Warning style={{ color: red[500] }} />
                )}
              </h5>
            </FlexBox>
          )}
          {isPaid || paymentType === CREDIT_BALANCE ? (
            <FlexBox justify={JUSTIFY_CENTER} flex={true}>
              {isThisUserCreatedOrder &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                showedOrderButton(orderStatus) &&
                orderStatus !== CANCELLED && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    disabled={isLoadingCancel}
                    variant={CONTAINED}
                    color={SECONDARY}
                    onClick={() => {
                      setIsLoadingCancel(true);
                      if (isFreePayTypeOrTotalZero) {
                        batchAsyncUpdateOrder({
                          privacy: PUBLIC,
                          supplier: DEFAULT_SUPPLIER,
                          warehouse: props.userWarehouse,
                          type: null,
                          batch_type: "inactive",
                          order_barcodes: [barcode],
                          order_status: CANCELLED,
                        })
                          .then(() => {
                            props.onRequestRefresh();
                            props.showSuccessMessage(
                              getLocalisationMessage("successfully"),
                            );
                          })
                          .catch(error => showErrorMessage1(error))
                          .finally(() => setIsLoadingCancel(false));
                      } else {
                        fetch(avvalSearchUrl, optionsPayment(true))
                          .then(response => response.json())
                          .then(rescheck => {
                            if (rescheck && rescheck.res_id === 103) {
                              setReceipt(orderId, {
                                check_number: orderId,
                                type: "CANCEL",
                                receipt: {
                                  TerminalID: getValue(
                                    rescheck,
                                    "data.terminal_id",
                                  ),
                                  ReceiptSeq: getValue(
                                    rescheck,
                                    "data.tartib_raqami",
                                  ),
                                  DateTime: getValue(
                                    rescheck,
                                    "data.date_time",
                                  ),
                                  FiscalSign: getValue(
                                    rescheck,
                                    "data.fiskal_belgi",
                                  ),
                                  AppletVersion: getValue(
                                    rescheck,
                                    "data.versiya",
                                  ),
                                  QRCodeURL: getValue(rescheck, "data.link"),
                                  checkID,
                                },
                              })
                                .then(resReceipt => {
                                  setRefreshChekId(prev => !prev);
                                  if (resReceipt) {
                                    batchAsyncUpdateOrder({
                                      privacy: PUBLIC,
                                      supplier: DEFAULT_SUPPLIER,
                                      warehouse: props.userWarehouse,
                                      type: null,
                                      batch_type: "inactive",
                                      order_barcodes: [barcode],
                                      order_status: CANCELLED,
                                    })
                                      .then(() => {
                                        props.onRequestRefresh();
                                        props.showSuccessMessage(
                                          getLocalisationMessage(
                                            "successfully",
                                          ),
                                        );

                                        setRefreshChekId(prev => !prev);
                                      })
                                      .catch(error => showErrorMessage1(error))
                                      .finally(() => setIsLoadingCancel(false));
                                  }
                                })
                                .catch(err => {
                                  showErrorMessage1(err);
                                  setIsLoadingCancel(false);
                                });
                            } else {
                              fetch(urlAVVAL, {
                                method: "POST",
                                body: JSON.stringify(createAVVALData(true)),
                              })
                                .then(response => response.json())
                                .then(res => {
                                  if (res && getValue(res, "code") === "1") {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        fp.toString(
                                          getValue(res, "resInfo.code"),
                                        ),
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (
                                    res &&
                                    getValue(res, "code") === "-1"
                                  ) {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        fp.toString(getValue(res, "resInfo")),
                                        getValue(res, "resInfo"),
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (
                                    res &&
                                    (getValue(
                                      res,
                                      "resInfo.result.QRCodeURL",
                                    ) === null ||
                                      !getValue(
                                        res,
                                        "resInfo.result.QRCodeURL",
                                      ))
                                  ) {
                                    showErrorMessage1(
                                      getLocalisationMessage(
                                        "the_payment_was_not_successful",
                                      ),
                                    );
                                    setIsLoadingCancel(false);
                                  } else if (checkAVVALres(res)) {
                                    setReceipt(orderId, {
                                      check_number: orderId,
                                      type: "CANCEL",
                                      receipt: {
                                        ..._.get(res, "resInfo.result"),
                                        checkID,
                                      },
                                    })
                                      .then(resReceipt => {
                                        setRefreshChekId(prev => !prev);
                                        if (resReceipt) {
                                          batchAsyncUpdateOrder({
                                            privacy: PUBLIC,
                                            supplier: DEFAULT_SUPPLIER,
                                            warehouse: props.userWarehouse,
                                            type: null,
                                            batch_type: "inactive",
                                            order_barcodes: [barcode],
                                            order_status: CANCELLED,
                                          })
                                            .then(() => {
                                              props.onRequestRefresh();
                                              props.showSuccessMessage(
                                                getLocalisationMessage(
                                                  "successfully",
                                                ),
                                              );
                                            })
                                            .catch(error =>
                                              showErrorMessage1(error),
                                            )
                                            .finally(() =>
                                              setIsLoadingCancel(false),
                                            );
                                        }
                                      })
                                      .catch(err => {
                                        showErrorMessage1(err);
                                        setIsLoadingCancel(false);
                                      });
                                  }
                                })
                                .catch(() => {
                                  showErrorMessage1(
                                    getLocalisationMessage(
                                      "user_is_not_connected_to_avval",
                                    ),
                                  );
                                })
                                .finally(() => setIsLoadingCancel(false));
                            }
                          })
                          .catch(() => {
                            setTimeout(() => setIsLoadingCancel(false), 1500);
                          });
                      }
                    }}
                    endIcon={
                      isLoadingCancel ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <DeleteForever />
                      )
                    }
                  >
                    {getLocalisationMessage("cancel_order")}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                orderStatus !== ISSUED_TO_RECIPIENT && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsOpenRedirect(true)}
                  >
                    {getLocalisationMessage("redirect_order")}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                orderStatus !== ISSUED_TO_RECIPIENT && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsOpenRTO(true)}
                  >
                    {getLocalisationMessage("return")}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) && (
                  <CustomButton
                    style={{ margin: "0 .5rem" }}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={() => setIsAssignDriver(true)}
                  >
                    {getLocalisationMessage("need_dispatch")}
                  </CustomButton>
                )}
              {!isPaidRTO &&
                showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                transportationType !== SIMPLE &&
                serviceTypes.includes(serviceType) &&
                Boolean(props.hasAccessToOrderRTOPayment) && (
                  <CustomButton
                    style={{ margin: "0 .5rem", width: "200px" }}
                    variant={CONTAINED}
                    onClick={() => {
                      setIsOpenRTOPrice(true);
                    }}
                    color={SECONDARY}
                  >
                    {getLocalisationMessage(
                      "payment_for_rto",
                      "Payment for RTO",
                    )}
                  </CustomButton>
                )}
              {showedOrderButton(orderStatus) &&
                isOrderLocatedHere &&
                isNotFinalStatus(orderStatus) &&
                Boolean(
                  props.hasAccessToOrderIssuance ||
                    props.userJurisdictionId ===
                      order.getIn(["to_jurisdiction", "id"]),
                ) && (
                  <CustomButton
                    style={{ margin: "0 .5rem", width: "200px" }}
                    variant={CONTAINED}
                    color={SECONDARY}
                    onClick={() => setIsOpenIssuance(true)}
                  >
                    {getLocalisationMessage("issuance")}
                  </CustomButton>
                )}
            </FlexBox>
          ) : (
            <FlexBox justify={JUSTIFY_CENTER} flex={true}>
              <CustomButton
                disabled={
                  isLoadingAVVAL ||
                  (order.get("payment_type") === CARD &&
                    !order.get("ppt_id") &&
                    !order.get("card_type"))
                }
                className={classes.btn}
                variant={CONTAINED}
                color={SECONDARY}
                onClick={() => {
                  setIsLoadingAVVAL(true);
                  if (isFreePayTypeOrTotalZero) {
                    postBatchChargeItems([orderId], {
                      charges,
                      order_ids: [orderId],
                    })
                      .then(() => {
                        props.onRequestRefresh();
                        props.showSuccessMessage(
                          getLocalisationMessage(
                            "successfully_created",
                            "Successfully Created",
                          ),
                        );
                      })
                      .catch(error => {
                        showErrorMessage1(error);
                      })
                      .finally(() => {
                        if (props.refreshOrderList) {
                          props.refreshOrderList();
                        }
                        setIsLoadingAVVAL(false);
                      });
                  } else {
                    fetch(avvalSearchUrl, optionsPayment())
                      .then(response => response.json())
                      .then(rescheck => {
                        if (rescheck && rescheck.res_id === 103) {
                          setReceipt(orderId, {
                            check_number: orderId,
                            receipt: {
                              TerminalID: getValue(
                                rescheck,
                                "data.terminal_id",
                              ),
                              ReceiptSeq: getValue(
                                rescheck,
                                "data.tartib_raqami",
                              ),
                              DateTime: getValue(rescheck, "data.date_time"),
                              FiscalSign: getValue(
                                rescheck,
                                "data.fiskal_belgi",
                              ),
                              AppletVersion: getValue(rescheck, "data.versiya"),
                              QRCodeURL: getValue(rescheck, "data.link"),
                              checkID,
                            },
                            type: "SALE",
                          })
                            .then(resAvval => {
                              setRefreshChekId(prev => !prev);

                              if (resAvval) {
                                props.onRequestRefresh();

                                if (props.refreshOrderList) {
                                  props.refreshOrderList();
                                }
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                                props.showSuccessMessage(
                                  getLocalisationMessage(
                                    "successfully_created",
                                    "Successfully Created",
                                  ),
                                );
                              }
                            })
                            .catch(error => {
                              showErrorMessage1(error);
                            });
                        } else {
                          fetch(urlAVVAL, {
                            method: "POST",
                            body: JSON.stringify(createAVVALData()),
                          })
                            .then(response => response.json())
                            .then(res => {
                              if (res && getValue(res, "code") === "1") {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo.code")),
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                getValue(res, "code") === "-1"
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    fp.toString(getValue(res, "resInfo")),
                                    getValue(res, "resInfo"),
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (
                                res &&
                                (getValue(res, "resInfo.result.QRCodeURL") ===
                                  null ||
                                  !getValue(res, "resInfo.result.QRCodeURL"))
                              ) {
                                showErrorMessage1(
                                  getLocalisationMessage(
                                    "the_payment_was_not_successful",
                                  ),
                                );
                                setTimeout(
                                  () => setIsLoadingAVVAL(false),
                                  1500,
                                );
                              } else if (checkAVVALres(res)) {
                                setReceipt(orderId, {
                                  check_number: orderId,
                                  receipt: {
                                    ..._.get(res, "resInfo.result"),
                                    checkID,
                                  },
                                  type: "SALE",
                                })
                                  .then(() => {
                                    setRefreshChekId(prev => !prev);
                                    props.onRequestRefresh();
                                    props.showSuccessMessage(
                                      getLocalisationMessage(
                                        "successfully_created",
                                        "Successfully Created",
                                      ),
                                    );
                                  })
                                  .catch(error => {
                                    showErrorMessage1(error);
                                  });
                              }
                            })
                            .catch(() => {
                              showErrorMessage1(
                                getLocalisationMessage(
                                  "user_is_not_connected_to_avval",
                                ),
                              );
                            })
                            .finally(() => {
                              if (props.refreshOrderList) {
                                props.refreshOrderList();
                              }
                              setIsLoadingAVVAL(false);
                            });
                        }
                      })
                      .catch(() => {
                        setTimeout(() => setIsLoadingAVVAL(false), 1500);
                      });
                  }
                }}
                endIcon={
                  isLoadingAVVAL ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <Check />
                  )
                }
              >
                {getLocalisationMessage(
                  isFreePayTypeOrTotalZero
                    ? "accept_payment"
                    : "pay_with_avval",
                )}
              </CustomButton>
            </FlexBox>
          )}
        </FlexBox>
      </div>
    </ModalPaper>
  );
}

OrderDetailsDialog.propTypes = {
  open: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    HISTORY_TAB,
    CUSTOMER_TAB,
    COURIER_TAB,
    MAP_TAB,
    SIGNATURE_TAB,
    COD_HISTORY_TAB,
    PROOF_OF_PAYMENT,
  ]),
  onEditClick: PropTypes.func,
  createDriverSuggestHref: PropTypes.func,
  onEditCostsClick: PropTypes.func,
  onShowSuggestedDriverLink: PropTypes.bool,
  onCancelOrderClick: PropTypes.func,
  children: PropTypes.node,
  withoutCourierTab: PropTypes.bool,
  withoutSignaturesTab: PropTypes.bool,
  withoutCustomerTab: PropTypes.bool,
  getDriverUrl: PropTypes.func,
  getSupplierUrl: PropTypes.func,
  getReverseUrl: PropTypes.func,
  getCustomerUrl: PropTypes.func,
  isOrderClosed: PropTypes.bool,
  withoutMap: PropTypes.bool,
  withCODHistory: PropTypes.bool,
  withTaxInvoice: PropTypes.bool,
  openRTODialog: PropTypes.bool,
  lastSyncDate: PropTypes.string,
  setTab: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  userWarehouseId: PropTypes.number,
  userJurisdictionId: PropTypes.number,
  hasAccessToOrderIssuance: PropTypes.bool,
  hasAccessToOrderRTOPayment: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
  refreshOrderList: PropTypes.func,
  userWarehouse: PropTypes.object,
  userId: PropTypes.number,
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
};
export default enhancer(OrderDetailsDialog);
